<template>
  <b-overlay :show="isLoading">
    <b-row>
      <b-col cols="12">
        <b-card>
          <h1>لیست بلاگ ها</h1>
          <b-table
            class="position-relative"
            :items="blogsList"
            responsive
            :fields="myTableColumns"
            primary-key="id"
            show-empty
            bordered
            striped
            empty-text="موردی موجود نیست!"
          >
            <!-- eslint-disable-next-line -->
            <template #cell(actions)="data">
              <add-edit-delete-button
                :data="generateObjectForAddEditRemoveButton(data.item)"
                :routerEditName="'pages-blog-edit'"
                :routerCreateName="'pages-posts-create'"
                @renderDeleteModal="renderDeleteModal"
              ></add-edit-delete-button>
              <!-- <div
                class="d-flex align-items-center justify-content-between"
                style="gap: 2rem"
              >
                <router-link :to="`/pages/blog/${data.item.blogId}`">
                  <feather-icon
                    size="20"
                    icon="EditIcon"
                    style="cursor: pointer"
                    class="text-primary"
                  />
                </router-link>
                <b-link
                  class="d-flex flex-column justify-content-center align-align-items-center"
                  :to="{
                    name: 'pages-posts-create',
                    params: { langId: 1, mainId: data.item.mainId },
                    query: {
                      lang: 'انگلیسی',
                      title: data.item.title,
                      link: data.item.seourl,
                    },
                  }"
                >
                  <b-img height="12px" width="18px" src="/en-flag.png" />
                  <span style="font-size: 10px; margin-top: 2px">SET</span>
                </b-link>
                <b-button
                  variant="transparent"
                  @click="renderDeleteModal(data.item)"
                >
                  <feather-icon
                    size="20"
                    icon="Trash2Icon"
                    class="text-danger"
                  ></feather-icon>
                </b-button>
              </div> -->
            </template>
            <template #cell(status)="data">
              {{ blogStatuses[data.item.status] }}
            </template>
          </b-table>
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-center py-2"
          >
            <b-pagination
              v-model="pageNumber"
              :total-rows="totalCount"
              :per-page="count"
              first-number
              last-number
              class="mb-0 mt-1"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  style="transform: rotate(180deg)"
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      id="delete-modal"
      ok-variant="danger"
      title="حذف خبر"
      ok-title="بله"
      cancel-title="بستن"
      centered
      @ok="sendDeleteRequest"
    >
      <template v-if="tempBlog">
        <span>آیا از حذف خبر اطمینان دارید؟</span>
      </template>
    </b-modal>
  </b-overlay>
</template>

<script>
import { GetAllBlogs, BlogDeleteRequest } from "@/libs/Api/Blog";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AddEditDeleteButton from "@/views/components/utils/AddEditDeleteButton.vue";

export default {
  title: "لیست بلاگ ها",
  mounted() {
    this.getAllBlogsList();
  },
  watch: {
    pageNumber: {
      handler(val) {
        this.getAllBlogsList();
      },
    },
  },
  data() {
    return {
      isLoading: false,
      count: 10,
      pageNumber: 1,
      totalCount: null,
      categoryName: null,
      tagId: null,
      searchByTitle: null,
      date: null,
      blogsList: null,
      blogStatuses: {
        1: "منتشر شده",
        2: "آرشیو",
      },
      myTableColumns: [
        {
          key: "blogId",
          label: "شناسه بلاگ",
        },
        {
          key: "title",
          label: "عنوان بلاگ",
        },
        {
          key: "status",
          label: "وضعیت",
        },
        {
          key: "userInfo.fullName",
          label: "نویسنده",
        },
        {
          key: "actions",
          label: "عملیات",
        },
      ],
      tempBlog: null,
    };
  },
  methods: {
    generateObjectForAddEditRemoveButton(blogDetails) {
      return {
        id: blogDetails.blogId,
        languageId: blogDetails.languageId,
        otherLanguages: blogDetails.otherLanguages || [],
        mainId: blogDetails.mainId,
      };
    },
    renderDeleteModal(blog) {
      this.tempBlog = JSON.parse(JSON.stringify(blog));
      this.$bvModal.show("delete-modal");
    },
    async sendDeleteRequest() {
      try {
        this.isLoading = true;
        let _this = this;
        let blogDeleteRequest = new BlogDeleteRequest(_this);
        blogDeleteRequest.setId(_this.tempBlog.id);
        await blogDeleteRequest.fetch((response) => {
          if (response.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              props: {
                title: "خبر با موفقیت حذف شد",
                icon: "Trash2Icon",
                variant: "success",
              },
            });
            _this.getAllBlogsList();
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async getAllBlogsList() {
      try {
        this.isLoading = true;
        let _this = this;
        let getAllBlogs = new GetAllBlogs(_this);
        let qParams = {
          count: _this.count,
          pageNumber: _this.pageNumber,
        };
        if (this.categoryName) {
          qParams["categoryName"] = this.categoryName;
        }
        if (this.tagId) {
          qParams["tagId"] = this.tagId;
        }
        if (this.searchByTitle) {
          qParams["searchByTitle"] = this.searchByTitle;
        }
        if (this.date) {
          qParams["date"] = this.date;
        }
        getAllBlogs.setRequestParam(qParams);
        await getAllBlogs.fetch((response) => {
          if (response.isSuccess) {
            _this.blogsList = response.data.blogs;
            _this.totalCount = response.data.blogsCount;
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  components: {
    BFormRow,
    BContainer,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BTable,
    BImg,
    BMedia,
    BAvatar,
    BModal,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    AddEditDeleteButton,
  },
};
import {
  BContainer,
  BCard,
  BRow,
  BFormRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
  BTable,
  BImg,
  BMedia,
  BAvatar,
  BModal,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BOverlay,
} from "bootstrap-vue";
</script>
